import React from "react";

import Image from "next/image";
import { useRouter } from "next/router";

import useTranslation from "next-translate/useTranslation";

import frame from "shared/assets/images/frame_phone.png";
import { useGlobalContext } from "shared/utils/hooks/useGlobalContext";

import { ColContent, Custom404Container, PrimaryButton, Title } from "./styles";

const Custom404 = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { state } = useGlobalContext();

  const siteName = state.tenancy.settings.site.siteName;

  return (
    <Custom404Container>
      <Title span={24}>{t("404:error404")}</Title>

      <ColContent span={24}>
        <h1>{t("404:pageNotExistsTitle")}</h1>
      </ColContent>

      <ColContent span={24}>{t("404:pageNotExists")}</ColContent>

      <ColContent span={24} button="true">
        <PrimaryButton type="primary" onClick={() => router.push("/")}>
          {siteName}
        </PrimaryButton>
      </ColContent>

      <ColContent span={24}>
        <div
          style={{ position: "relative", width: "600px", padding: "8px 16px" }}
        >
          <Image src={frame} alt="..." objectFit="contain" />
        </div>
      </ColContent>
    </Custom404Container>
  );
};

export default Custom404;
